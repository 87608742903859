import revive_payload_client_Iqh3j9bMIx from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_sass@1.69.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_g4WD4ZgxTF from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_sass@1.69.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_d47NPosWV3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_sass@1.69.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_48tOvI7vOk from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_sass@1.69.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_0snexNijI1 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_AmWC3UZ7VS from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_sass@1.69.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_7mNpmSDdrY from "/vercel/path0/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.6/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_ruLGwaMSfd from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.7.4+vue@3.3.4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_cgqgtuVIEe from "/vercel/path0/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.7_vue@3.3.4/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_3iNR0Ei7Fz from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_sass@1.69.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_Iqh3j9bMIx,
  unhead_g4WD4ZgxTF,
  router_d47NPosWV3,
  payload_client_48tOvI7vOk,
  plugin_vue3_0snexNijI1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_AmWC3UZ7VS,
  plugin_client_7mNpmSDdrY,
  plugin_ruLGwaMSfd,
  plugin_cgqgtuVIEe,
  chunk_reload_client_3iNR0Ei7Fz
]