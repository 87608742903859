import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoBedrock, LazySvgoBrush, LazySvgoEmail, LazySvgoLaravel, LazySvgoLinkedin, LazySvgoLogoW, LazySvgoNuxt, LazySvgoPhone, LazySvgoPuzzle, LazySvgoSage, LazySvgoWand, LazySvgoWhatsapp, LazySvgoWoocommerce } from '#components'
const lazyGlobalComponents = [
  ["SvgoBedrock", LazySvgoBedrock],
["SvgoBrush", LazySvgoBrush],
["SvgoEmail", LazySvgoEmail],
["SvgoLaravel", LazySvgoLaravel],
["SvgoLinkedin", LazySvgoLinkedin],
["SvgoLogoW", LazySvgoLogoW],
["SvgoNuxt", LazySvgoNuxt],
["SvgoPhone", LazySvgoPhone],
["SvgoPuzzle", LazySvgoPuzzle],
["SvgoSage", LazySvgoSage],
["SvgoWand", LazySvgoWand],
["SvgoWhatsapp", LazySvgoWhatsapp],
["SvgoWoocommerce", LazySvgoWoocommerce],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
